const state = {
    languageOptions: [
        {
            "code": "EN",
            "language": "English"
        },
        {
            "code": "FR",
            "language": "French"
        },
        /*{
            "code": "RUN",
            "language": "Kirundi"
        },*/
    ],
    currentLanguage: "EN",
    navigation: {
        "site-title": {
            EN: "Great Lakes Accelerated Innovation Delivery Initiative Rapid Delivery Hub (AID-I GLR)",
            FR: "Initiative de livraison accélérée de l'innovation dans la région des Grands Lacs (AID-| GLR)",
            RUN: "",
        },
        "Videos": {
            FR: "Vidéos",
            RUN: ""
        },
        "About": {
            FR: "Sur",
            RUN: ""
        },
        "Website": {
            FR: "",
            RUN: ""
        }
    },
    footer: {
        "Contact us at": {
            FR: "Contactez nous à",
            RUN: ""
        },
        "Disclaimer": {
            FR: "Avis de non-responsabilité",
            RUN: ""
        },
        "usaid-disclaimer": {
            EN: "The information provided on this website is not official U.S. Government information and does not represent the views or positions of the U.S. Agency for International Development or the U.S. Government.",
            FR: "Les informations fournies sur ce site Web ne sont pas des informations officielles du gouvernement des États-Unis et ne représentent pas les points de vue ou les positions de l'Agence des États-Unis pour le développement international ou du gouvernement des États-Unis.",
            RUN: ""
        }
    },
    videoPage: {
        "Language": {
            FR: "Langue",
            RUN: ""
        },
        "Country": {
            FR: "Pays",
            RUN: ""
        },
        "Subtitles": {
            FR: "Les sous-titres",
            RUN: ""
        },
        "Select a Language:": {
            FR: "Sélectionnez une langue",
            RUN: ""
        },
        "Download:": {
            FR: "Téléchargez",
            RUN: ""
        },
        "WhatsApp": {
            FR: "WhatsApp",
            RUN: "WhatsApp"
        },
        "Computer": {
            FR: "Ordinateur",
            RUN: ""
        },
        "Broadcast": {
            FR: "Diffusion",
            RUN: ""
        },
        "Smartphone": {
            FR: "Smartphone",
            RUN: ""
        },
        "Cellphone": {
            FR: "Téléphone portable",
            RUN: ""
        },
        "Audio": {
            FR: "L'audio",
            RUN: ""
        }
    },
    videoTitles: {
        "PHLJerryCan": {
            EN: "Postharvest Loss: Jerrycan Bean Storage",
            FR: "Conservation de graines de niébé avec les bidons hermétiques",
            RUN: "",
        },
        "RocksGrateCooking": {
            EN: "How to Reduce Firewood and Fuel in Cooking: Using Rocks and a Grate",
            FR: "Comment réduire le bois de chauffage et le combustible dans la cuisine : utiliser des pierres et une grille",
            RUN: "",
        },
        "BananaSucker": {
            EN: "Preparing Banana and Plantain Suckers for Planting to Reduce Pests and Increase Yield and Stem Trapping to Control Weevils",
            FR: "Préparation des rejets de bananes et de plantains pour la plantation afin de réduire les ravageurs et d'augmenter le rendement et le piégeage des tiges pour contrôler les charançons",
            RUN: "",
        },
        "SGComposting3DIITA": {
            EN: "Survival Gardening: How to Create Compost (3D) IITA",
            FR: "Jardinage de survie : Comment créer du compost (3D) IITA",
            RUN: "",
        },
        "BananaSuckerIITA": {
            EN: "Preparing Banana and Plantain Suckers for Planting to Reduce Pests and Increase Yield and Stem Trapping to Control Weevils IITA",
            FR: "Préparation des drageons de bananiers et de plantains pour la plantation afin de réduire les ravageurs et d'augmenter le rendement, et piégeage des tiges pour lutter contre les charançons IITA",
            RUN: "",
        },
        "BiocontrolPushPullIITA": {
            EN: "Climate-smart Push-pull System for Stem Borer Management in Maize IITA",
            FR: "Système push-pull climato-intelligent pour la gestion des foreurs de tiges dans le maïs IITA",
            RUN: "",
        },
        "PHLJerryCanIITA": {
            EN: "Postharvest Loss: Jerrycan Bean Storage IITA",
            FR: "Perte après récolte : stockage des haricots jerrycans IITA",
            RUN: "",
        },
        "RocksGrateCookingIITA": {
            EN: "How to Reduce Firewood and Fuel in Cooking: Using Rocks and a Grate IITA",
            FR: "Comment réduire le bois de chauffage et le combustible dans la cuisine : utiliser des pierres et une bonne idée",
            RUN: "",
        },
        "NeemIITA": {
            EN: "Natural Insecticide from Neem Seeds IITA",
            FR: "Insecticide naturel à base de graines de Neem IITA",
            RUN: "",
        },
        "PHLSaltTestIITA": {
            EN: "Postharvest Loss: Salt Testing for Grain Moisture Levels IITA",
            FR: "Perte après récolte : analyse du sel pour déterminer les niveaux d'humidité des grains IITA",
            RUN: "",
        },
        "ShrubIITA": {
            EN: "Using Native Shrubs to Increase Soil Health and Crop Yield IITA",
            FR: "Utiliser des arbustes indigènes pour améliorer la santé des sols et le rendement des cultures IITA",
            RUN: "",
        },
        "PHLBeanRowPlantingIITA": {
            EN: "Improved Bean Production IITA",
            FR: "Production améliorée de haricots IITA",
            RUN: "",
        },
        "SweetPotatoRootsDoubleSIITA": {
            EN: "Double S: Harvesting and Storing Your Sweet Potato Crop IITA",
            FR: "Double S : Récolter et stocker votre récolte de patate douce IITA",
            RUN: "",
        },
        "SoilHealth": {
            EN: "Invest in your soil, above and below the surface.",
            FR: "Investissez dans votre sol, au-dessus et au-dessous de la surface.",
            RUN: "",
        },
    },
    videoDescriptions: {
        "PHLJerryCan": {
            EN: "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
            FR: "Cette animation explique comment éviter les dégâts causés par les insectes lors du stockage du niébé après la récolte en utilisant les bidons. Le niébé peut être stocké dans un bidon hermétique pendant de longues périodes et en toute sécurité. Le bidon utilisé doit être propre et exempt de tout contaminant. Il ne doit jamais avoir été utilisé pour le stockage des matières dangereuses comme le carburant ou même les pesticides.",
            RUN: "",
        },
        "RocksGrateCooking": {
            EN: "In this video, you will learn how to save firewood and charcoal by using rocks and a grate while cooking. Using this technique in your wood stove allows air to circulate under the fire, making the fire burn hotter and with less smoke and using less fire wood. Rocks can help to save charcoal in your charcoal cook stove as well.",
            FR: "Dans cette vidéo, vous apprendrez à économiser du bois de chauffage et du charbon de bois en utilisant des pierres et une grille pendant la cuisson. L'utilisation de cette technique dans votre poêle à bois permet à l'air de circuler sous le feu, ce qui rend le feu plus chaud et avec moins de fumée et en utilisant moins de bois de chauffage. Les pierres peuvent également aider à économiser du charbon de bois dans votre poêle à charbon de bois.",
            RUN: "",
        },
        "BananaSucker": {
            EN: "In this video, you will learn how to prepare suckers before planting to remove pests, and how to use pieces of cut stems to trap and control weevils. Following these steps will result in higher yields and better crops.",
            FR: "Dans cette vidéo, vous apprendrez à préparer les rejets avant la plantation pour éliminer les ravageurs et à utiliser des morceaux de tiges coupées pour piéger et contrôler les charançons. Suivre ces étapes se traduira par des rendements plus élevés et de meilleures récoltes.",
            RUN: "",
        },
        "SGComposting3DIITA": {
            EN: "Compost can be used to improve the quality of your soil. You can use plant materials, animal manure and kitchen scraps to create compost. Compost will add nutrients and organic matter to your soil. This animation explains the process of creating and storing compost.",
            FR: "Le compost peut être utilisé pour améliorer la qualité de votre sol. Vous pouvez utiliser des matières végétales, du fumier animal et des restes de cuisine pour créer du compost. Le compost ajoutera des nutriments et de la matière organique à votre sol. Cette animation explique le processus de création et de stockage du compost.",
            RUN: "",
        },
        "BananaSuckerIITA": {
            EN: "In this video, you will learn how to prepare suckers before planting to remove pests, and how to use pieces of cut stems to trap and control weevils. Following these steps will result in higher yields and better crops.",
            FR: "Dans cette vidéo, vous apprendrez comment préparer les drageons avant la plantation pour éliminer les parasites, et comment utiliser des morceaux de tiges coupées pour piéger et lutter contre les charançons. Suivre ces étapes se traduira par des rendements plus élevés et de meilleures récoltes.",
            RUN: "",
        },
        "BiocontrolPushPullIITA": {
            EN: "Growing maize is hard work and pests make it even harder. Insect pests can attack your maize crop and destroy it, affecting your family's nutrition, or costing you money. This animation will take you through the steps of protecting your field from pests like the stem borers by using a push-pull system. The push-pull system is a key element in developing an Integrated Pest Management program.",
            FR: "La culture du maïs est un travail difficile et les ravageurs le rendent encore plus difficile. Les insectes nuisibles peuvent attaquer votre récolte de maïs et la détruire, affectant ainsi la nutrition de votre famille ou vous coûtant de l'argent. Cette animation vous guidera à travers les étapes de protection de votre champ contre les ravageurs comme les foreurs de tiges en utilisant un système push-pull. Le système push-pull est un élément clé dans le développement d’un programme de lutte antiparasitaire intégrée.",
            RUN: "",
        },
        "PHLJerryCanIITA": {
            EN: "This animation explains how to avoid insect damage when storing beans after harvest using the jerrycan method. Beans can be stored in an airtight jerrycan for long periods of time safely. The jerrycan being used must be completely clean and free of any contaminants. It can never have been used for any hazardous materials such as fuel or pesticides.",
            FR: "Cette animation explique comment éviter les dommages causés par les insectes lors du stockage des haricots après la récolte en utilisant la méthode du jerrycan. Les haricots peuvent être conservés dans un jerrycan hermétique pendant de longues périodes en toute sécurité. Le jerrycan utilisé doit être complètement propre et exempt de tout contaminant. Il ne peut jamais avoir été utilisé pour des matières dangereuses telles que du carburant ou des pesticides.",
            RUN: "",
        },
        "RocksGrateCookingIITA": {
            EN: "In this video, you will learn how to save firewood and charcoal by using rocks and a grate while cooking. Using this technique in your wood stove allows air to circulate under the fire, making the fire burn hotter and with less smoke and using less fire wood. Rocks can help to save charcoal in your charcoal cook stove as well.",
            FR: "Dans cette vidéo, vous apprendrez comment économiser du bois de chauffage et du charbon de bois en utilisant des pierres et une grille pendant la cuisson. L'utilisation de cette technique dans votre poêle à bois permet à l'air de circuler sous le feu, rendant le feu plus chaud et avec moins de fumée et utilisant moins de bois de chauffage. Les pierres peuvent également aider à économiser du charbon de bois dans votre cuisinière à charbon.",
            RUN: "",
        },
        "NeemIITA": {
            EN: "Naturally occurring insecticidal compounds can be extracted from neem seeds and sprayed onto crops to prevent pest insect damage. This animation explains how to sort neem fruits, dry them, remove their outer shells, sort the seeds, grind them, mix the powder in water, filter the mixture, and then prepare the solution necessary for spraying on the crops.",
            FR: "Des composés insecticides naturels peuvent être extraits des graines de neem et pulvérisés sur les cultures pour prévenir les dommages causés par les insectes nuisibles. Cette animation explique comment trier les fruits de neem, les sécher, retirer leurs coques extérieures, trier les graines, les broyer, mélanger la poudre dans l'eau, filtrer le mélange, puis préparer la solution nécessaire à la pulvérisation sur les cultures.",
            RUN: "",
        },
        "PHLSaltTestIITA": {
            EN: "This animation explains a method to test if your grain has a low enough moisture level for you to store it. This technique utilizes salt to help determine the moisture level in your grain.",
            FR: "Cette animation explique une méthode pour tester si votre grain a un niveau d'humidité suffisamment bas pour que vous puissiez le stocker. Cette technique utilise du sel pour aider à déterminer le niveau d'humidité de votre grain.",
            RUN: "",
        },
        "ShrubIITA": {
            EN: "There is an easy way you can achieve higher yields while increasing soil health, just by adding the leaves and stems from the native shrubs that are already in your field back into the soil. In this video, you will learn the benefits of keeping shrubs in your field.",
            FR: "Il existe un moyen simple d'obtenir des rendements plus élevés tout en améliorant la santé du sol, simplement en ajoutant dans le sol les feuilles et les tiges des arbustes indigènes qui se trouvent déjà dans votre champ. Dans cette vidéo, vous découvrirez les avantages de conserver des arbustes dans votre champ.",
            RUN: "",
        },
        "PHLBeanRowPlantingIITA": {
            EN: "Common beans are an important source of food and income for farmers and families. By improving your bean planting and cultivation practices you can have more beans to eat and sell. This animation shows you steps you can take to improve your bean production.",
            FR: "Les haricots communs constituent une source importante de nourriture et de revenus pour les agriculteurs et leurs familles. En améliorant vos pratiques de plantation et de culture des haricots, vous pouvez avoir plus de haricots à manger et à vendre. Cette animation vous montre les étapes que vous pouvez suivre pour améliorer votre production de haricots.",
            RUN: "",
        },
        "SweetPotatoRootsDoubleSIITA": {
            EN: "There is a better way. Storing your harvested sweet potatoes in dry cool sand prevents the spread of disease or pests for many months. This video will show you how to harvest and store fresh sweet potato roots for several months without rot.",
            FR: "Il y a un meilleur moyen. Conserver vos patates douces récoltées dans du sable sec et frais empêche la propagation de maladies ou de ravageurs pendant plusieurs mois. Cette vidéo vous montrera comment récolter et conserver des racines de patates douces fraîches pendant plusieurs mois sans pourrir.",
            RUN: "",
        },
        "SoilHealth": {
            EN: "Invest in your soil, above and below the surface.",
            FR: "Investissez dans votre sol, au-dessus et au-dessous de la surface.",
            RUN: "",
        },
        
    },
    aboutFiles: {
        EN: "aboutFile.html",
        FR: "aboutFileFrench.html",
        RUN: "aboutFileKirundi.html",
    },
}

const mutations = {
    'CHANGE_CURRENT_LANGUAGE' (state, language) {
        state.currentLanguage = language;
    }
}

const actions = {
    changeLanguage({commit}, language) {
        commit('CHANGE_CURRENT_LANGUAGE', language);
    }
}

const getters = {
    languageOptions: state => {
        return state.languageOptions;
    },
    currentLanguage: state => {
        return state.currentLanguage;
    },
    navigation: state => {
        return state.navigation;
    },
    footer: state => {
        return state.footer;
    },
    videoPage: state => {
        return state.videoPage;
    },
    videoTitles: state => {
        return state.videoTitles;
    },
    displayText: state => (text, section) => {
        let displayText = text;
        if (state.currentLanguage !== "EN") {
          displayText = state[section][text][state.currentLanguage];
        }
        return displayText;
    },
    displayTextCode: state => (code, section) => {
        return state[section][code][state.currentLanguage];
    },
    aboutFiles: state => {
        return state.aboutFiles;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}